import React, { useReducer } from 'react';
import ProductReducer from './ProductReducer';
import ProductContext from './ProductContext';
import productService from '../../services/productService';
import {
  ADD_IMAGES_TO_PICK_PRODUCT, CHANGE_FEATURES,
  CHANGE_FILTER,
  CHANGE_PRODUCTS,
  CLEAR_IMPORT_EXPORT_RESPONSE,
  CLEAR_PRODUCTS,
  DELETE_PRODUCT,
  DISMISS_DELETE_DIALOG, EDIT_FEATURE,
  EXPORT_PRODUCTS_RESPONSE,
  IMPORT_PRODUCTS_RESPONSE,
  INIT_IMPORTS,
  LAUNCH_ERROR,
  PICK_PRODUCT,
  PRODUCT_CHANGE,
  SHOW_DELETE_DIALOG
} from './ProductActions';
import featureService from '../../services/featureService';

const ProductState = props => {
  const initialState = {
    products: [],
    totalProducts: 0,
    loading: false,
    productPicked: null,
    productChanged: null,
    error: null,
    filters: {
      category: 'all',
      outStock: false,
      query: null,
      page: 0
    },
    showDelete: false,
    productsImported: null,
    productsExported: null,
    features: [],
    featuresGroups: [],
    featureGroupSelected: undefined,
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  const filtersChange = async filter => {
    dispatch({
      type: CHANGE_FILTER,
      payload: filter
    });

    const { category, page, query, outStock } = filter;

    const productResponse = category !== 'all'
      ? await productService.fetchProductsByFamily(category, page, query, outStock ? 0 : undefined)
      : await productService.fetchProducts(page, query, outStock ? 0 : undefined);

    dispatch({
      type: CHANGE_PRODUCTS,
      payload: {
        products: productResponse.data ? productResponse.data : [],
        totalProducts: productResponse.headers
          ? parseInt(productResponse.headers['x-total-count'])
          : productResponse.data.length
      }
    });
  };

  const pickProduct = async productId => {
    const product = await productService.findProductById(productId);

    if (product) {
      dispatch({
        type: PICK_PRODUCT,
        payload: product
      });
    }

  };

  const addImagesToPickedProduct = newImages => {
    dispatch({
      type: ADD_IMAGES_TO_PICK_PRODUCT,
      payload: newImages
    });
  };

  const initProductFeatures = async productId => {
    const features = await featureService.findFeaturesByProductIdAsync(productId);
    const groups = new Set();
    features.forEach(feature => {
      groups.add(feature.group);
    });

    dispatch({
      type: CHANGE_FEATURES,
      payload: {
        features,
        groups: [...groups],
      }
    });
  }

  const upsetFeatures = async (productId, features) => {
    const updatedProduct = await featureService.upsertFeatures(productId, features);
    await initProductFeatures(productId);

    dispatch({
      type: PRODUCT_CHANGE,
      payload: updatedProduct
    });
  }

  const updateAdditionalInfo = async (productId, descriptionBlocks, features, pdfUrl, videoUrl) => {
    const productUpdated = await productService.updateAdditionalInfo(productId, descriptionBlocks, features, pdfUrl, videoUrl);

    if (productUpdated) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: updateProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido actualizar el producto'
      });
    }
  }

  const createProduct = async (allergens, amount, amountMode, description, erpId, families, name, options, photoUrls, salesFormat, stock, taxId, featured, specialLabel) => {

    const newProduct =
      await productService.createProduct(allergens, amount, amountMode, description, erpId, families, name, options, photoUrls, salesFormat, stock, taxId, featured, undefined, specialLabel);

    if (newProduct) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: newProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido crear el producto'
      });
    }
  };

  const updateProduct = async (id, allergens, amount, amountMode, description, erpId, familyIds, name, options, photoUrls, salesFormat, stock, taxId, featured, specialLabel) => {
    const updateProduct =
      await productService.updateProduct(id, allergens, amount, amountMode, description, erpId, familyIds, name, options, photoUrls, salesFormat, stock, taxId, featured, undefined, specialLabel);

    if (updateProduct) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: updateProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido actualizar el producto'
      });
    }
  };

  const clearProducts = () => {
    dispatch({
      type: CLEAR_PRODUCTS
    });
  };

  const showDeleteDialog = (product) => {
    dispatch({
      type: SHOW_DELETE_DIALOG,
      payload: product
    });
  };

  const dismissDeleteDialog = () => {
    dispatch({
      type: DISMISS_DELETE_DIALOG
    });
  };

  const deleteProduct = async productId => {
    const productDeleted = await productService.deleteProduct(productId);

    if (productDeleted) {
      dispatch({
        type: DELETE_PRODUCT,
        payload: productDeleted
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido eliminar el producto'
      });
    }
  };

  const importProducts = async data => {
    dispatch({
      type: INIT_IMPORTS
    });

    let success = 0;
    let errors = 0;
    await Promise.all(data.map(product =>
      productService.createImportProduct(product)
        .then(_ => success += 1)
        .catch(_ => errors += 1)));

    dispatch({
      type: IMPORT_PRODUCTS_RESPONSE,
      payload: {
        success,
        errors
      }
    });

    productService.fetchProducts(1, '').then(productResponse => {
      dispatch({
        type: CHANGE_PRODUCTS,
        payload: {
          products: productResponse.data ? productResponse.data : [],
          totalProducts: productResponse.headers
            ? parseInt(productResponse.headers['x-total-count'])
            : productResponse.data.length
        }
      });
    });
  };

  const clearImportExportResponse = () => {
    dispatch({
      type: CLEAR_IMPORT_EXPORT_RESPONSE
    });
  };

  const exportProducts = async filter => {

    const { category, query, outStock } = filter;

    const productResponse = category !== 'all'
      ? await productService.fetchProductsByFamily(category, 0, query, outStock ? 0 : undefined, 10000)
      : await productService.fetchProducts(0, query, outStock ? 0 : undefined, 10000);

    const productsExported =
      productResponse.data
        ? productResponse.data.map(product => {
          return {
            'ID': product.erpId,
            'NOMBRE': product.name,
            'DESCRIPCION': product.description,
            'FAMILIA': product.families.length > 0 ? product.families[0].erpId : '',
            'FOTO_URL_1': product.photoUrls.length > 0 ? product.photoUrls[0] : '',
            'FOTO_URL_2': product.photoUrls.length > 1 ? product.photoUrls[1] : '',
            'FOTO_URL_3': product.photoUrls.length > 2 ? product.photoUrls[2] : '',
            'STOCK': product.stock ? product.stock : -1
          }})
        : []

        dispatch({
          type: EXPORT_PRODUCTS_RESPONSE,
          payload: productsExported
        });
  };

  const editingFeature = async group => {
    dispatch({
      type: EDIT_FEATURE,
      payload: group
    });
  }

  return (
    <ProductContext.Provider value={{
      apiError: state.error,
      editFeature: state.editFeature,
      featureGroupSelected: state.featureGroupSelected,
      filters: state.filters,
      loading: state.loading,
      productChanged: state.productChanged,
      productPicked: state.productPicked,
      products: state.products,
      productsExported: state.productsExported,
      productsImported: state.productsImported,
      showDelete: state.showDelete,
      totalProducts: state.totalProducts,

      addImagesToPickedProduct,
      clearImportExportResponse,
      clearProducts,
      createProduct,
      deleteProduct,
      dismissDeleteDialog,
      editingFeature,
      exportProducts,
      filtersChange,
      importProducts,
      initProductFeatures,
      pickProduct,
      showDeleteDialog,
      updateAdditionalInfo,
      updateProduct,
      upsetFeatures,
    }}> {props.children}
    </ProductContext.Provider>
  );
};

export default ProductState;
