import axios from '../utils/axiosDavo';

class ProductService {
  constructor() {
    this._limit = 20;
  }

  fetchProducts = (page, query, stock, limit = this._limit) => axios.get(`/products?search_text=${query ? query : ""}&offset=${page * limit}&limit=${limit}&stock=${stock}`)
    .then(response => response)
    .catch(error => {
      console.error(error)
      return []
    });

  fetchVoucherProducts = (page, query, stock, limit = this._limit) => axios.get(`/products?search_text=${query ? query : ""}&offset=${page * limit}&limit=${limit}&stock=${stock}&type=voucher`)
    .then(response => response)
    .catch(error => {
      console.error(error)
      return []
    });

  findProductById = productId => axios.get(`/products/${productId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
    });

  fetchProductsByFamily = (familyId, page, query, stock, limit = this._limit) =>
    axios.get(`/families/${familyId}/products?search_text=${query ? query : ""}&offset=${page * limit}&limit=${limit}&stock=${stock}`)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return [];
    })

  createProduct = (allergens, amount, amountMode, description, erpId, families, name, options, photoUrls, salesFormat, stock, taxId, featured, voucherInfo, specialLabel) =>
    axios.post(`/products`, {
      allergens,
      amount,
      amountMode,
      description,
      erpId,
      families,
      name,
      options,
      photoUrls,
      salesFormat,
      stock,
      taxId,
      featured,
      voucherInfo,
      specialLabel
    }).then(response => response.data).catch(error => console.error(error))

  createImportProduct = ({ id, name, description, family, features, photoUrlOne, photoUrlTwo, photoUrlThree, stock }) => {
    const photoUrls = [];
    if (photoUrlOne) {
      photoUrls.push(photoUrlOne);
    }
    if (photoUrlTwo) {
      photoUrls.push(photoUrlTwo);
    }
    if (photoUrlThree) {
      photoUrls.push(photoUrlThree);
    }

    // El formato es el siguiente TALLA(M#L#XL)|COLOR(Rojo#Verde#Negro);
    const featuresArray = [];
    if (features) {
      const masterFeatures = features.replace('"', '').split('|');
      masterFeatures.forEach(masterFeature => {
          const splitFeature = masterFeature.split('(');
          const group = splitFeature[0].toString();
          const labels = splitFeature[1].replace(')', '').split('#');

          return labels.forEach(label => {
            featuresArray.push({
              label,
              group
            });
          });
        }
      );
    }

    const importProduct = {
      erpId: id,
      name,
      description,
      familyErpId: family,
      features: featuresArray,
      photoUrls,
      stock
    }

    return axios.put(`/products?from=csv`, importProduct);
  }

  updateProduct = (productId, allergens, amount, amountMode, description, erpId, familyIds, name, options, photoUrls, salesFormat, stock, taxId, featured, voucherInfo, specialLabel) =>
    axios.put(`/products/${productId}`, {
      allergens,
      amount,
      amountMode,
      description,
      erpId,
      familyIds,
      name,
      options,
      photoUrls,
      salesFormat,
      stock,
      taxId,
      featured,
      voucherInfo,
      specialLabel
    }).then(response => response.data).catch(error => console.error(error))

  updateAdditionalInfo = (productId, descriptionBlocks, features, pdfUrl, videoUrl) => axios.put(`/products/${productId}`, {
    descriptionBlocks,
    features,
    pdfUrl,
    videoUrl
  })

  deleteProduct = async productId =>
    axios.delete(`/products/${productId}`)
      .then(response => response.data)
      .catch(error => console.error(error))
}

const productService = new ProductService();

export default productService;
