import OrderContext from './OrderContext';
import {
  API_RESPONSE,
  CHANGE_FILTER,
  CLEAN_IMPORT_EXPORT_RESPONSE,
  CLEAR_API_STATUS, EXPORT_DETAILS, EXPORT_ORDERS,
  IMPORT_ORDERS,
  INIT_IMPORTS,
  ORDERS_CHANGE,
  PICK_ORDER,
  UPDATE_ORDER
} from './OrderActions';

import React, { useReducer } from 'react';
import OrderReducer from './OrderReducer';
import orderService from '../../services/orderService';
import groupBy from '../../utils/groupBy';

const OrderState = props => {
  const initialState = {
    orders: [],
    orderPicked: null,
    orderChanged: null,
    apiResult: null,
    ordersExported: null,
    detailsExported: null,
    ordersImported: null,
    totalOrders: 0,
    filters: {
      page: 0,
      query: '',
      status: 'all',
      type: 'all'
    },
    loading: false
  };

  const [state, dispatch] = useReducer(OrderReducer, initialState);

  const fetchOrders = async (query = '', status = 'all', type = 'all', page = 0, limit = 20) => {
    const ordersResponse = await orderService.fetchOrders(query, status, type, page, limit);

    if (ordersResponse) {
      dispatch({
        type: ORDERS_CHANGE,
        payload: {
          orders: ordersResponse.data ? ordersResponse.data : [],
          totalOrders: ordersResponse.headers
            ? parseInt(ordersResponse.headers["x-total-count"])
            : ordersResponse.data.length
        }
      })
    } else {
      dispatch({
        type: API_RESPONSE,
        payload: {
          action: 'get',
          type: 'error',
          message: 'No se han podido cargar los pedidos'
        }
      })
    }
  };

  const changeFilters = async filters => {
    dispatch({
      type: CHANGE_FILTER,
      payload: filters
    });

    fetchOrders(filters.query, filters.status, filters.type, filters.page);
  }

  const getOrderById = async orderId => {
    const order = await orderService.findOrderById(orderId);

    dispatch({
      type: PICK_ORDER,
      payload: order
    })
  };

  const launchMessage = async (action, status, message) => {
      dispatch({
        type: API_RESPONSE,
        payload: {
          action,
          status,
          message
        }
      });
  };

  const updateOrderDetails = async (clientId, orderId, details) => {
    const orderUpdated = await orderService.updateOrderDetails(clientId, orderId, details);

    if (orderUpdated) {
      dispatch({
        type: UPDATE_ORDER,
        payload: orderUpdated
      });
    } else {
      dispatch({
        type: API_RESPONSE,
        payload: {
          action: 'update',
          status: 'error',
          message: 'Error actualizando el pedido'
        }
      })
    }
  };

  const updateOrderStatus = async (clientId, orderId, status) => {
    const orderUpdated = await orderService.updateStatusOrder(clientId, orderId, status);

    if (orderUpdated) {
      dispatch({
        type: UPDATE_ORDER,
        payload: orderUpdated
      })
    } else {
      dispatch({
        type: API_RESPONSE,
        payload: {
          action: 'update',
          status: 'error',
          message: 'Error actualizando el pedido'
        }
      })
    }
  };

    const updateOrderPaymentInfo = async (clientId, orderId, paymentInfo) => {
      const orderUpdated = await orderService.updateOrderPaymentInfo(clientId, orderId, paymentInfo);

      if (orderUpdated) {
        dispatch({
          type: UPDATE_ORDER,
          payload: orderUpdated
        })
      } else {
        dispatch({
          type: API_RESPONSE,
          payload: {
            action: 'update',
            status: 'error',
            message: 'Error actualizando el pedido'
          }
        })
      }
    };

    const clearApiStatus = () => {
      dispatch({
        type: CLEAR_API_STATUS
      })
    };

    const importDetails = async data => {
      const groups = groupBy(data, 'orderErpId');
      let success = 0;
      let errors = 0;

      // Nos llegan todos los details del csv. Los agrupamos por order dejando
      // el orderErpId como clave y el array de details como value.
      const ordersForUpdating = [];
      for (const [key, value] of Object.entries(groups)) {
        const details = value.map(detailData => {
          return {
            quantity: parseInt(detailData.quantity),
            productErpId: detailData.productErpId
          }
        });
        ordersForUpdating.push(orderService.importDetail(key, details)
          // eslint-disable-next-line
          .then(_ => success += 1)
          // eslint-disable-next-line
          .catch(_ => errors += 1));
      }

      await Promise.all(ordersForUpdating);

      dispatch({
        type: IMPORT_ORDERS,
        payload: {
          success,
          errors
        }
      })

      fetchOrders();
    }

    const importOrders = async data => {
      dispatch({
        type: INIT_IMPORTS
      });
      
      let success = 0;
      let errors = 0;
      await Promise.all(data.map(orderData => orderService.importOrder(orderData)
        .then(_ => success += 1)
        .catch(_ => errors += 1)));

      dispatch({
        type: IMPORT_ORDERS,
        payload: {
          success,
          errors
        }
      });

      fetchOrders();
    };

    const cleanImportExportResponse = () => {
      dispatch({
        type: CLEAN_IMPORT_EXPORT_RESPONSE
      })
    };

    const exportOrderHeaders = async filters => {
      const ordersResponse = await orderService.fetchOrders(filters.query, filters.status, filters.type, 0, 1000);

      const headersExported = ordersResponse.data
        ? ordersResponse.data.map(order => {
          return {
            'REF_PEDIDO': order.erpId,
            'CLIENTE': order.client.billingInfo ? order.client.billingInfo.name : order.client.name,
            'ID_CLIENTE': order.client.erpId ? order.client.erpId : 'ID_DESCONOCIDO',
            'PROVINCIA': order.shippingAddress.province.name,
            'CIUDAD': order.shippingAddress.city,
            'CALLE': order.shippingAddress.street,
            'FECHA': order.createdAt,
            'METODO_ENVIO': order.shippingMethod.name,
            'ESTADO_PEDIDO': order.status,
            'TOTAL': order.finalPrice ? order.finalPrice.toFixed(2) : order.estimatedPrice.toFixed(2),
            'IVA': order.taxes.toFixed(2),
            'METODO_PAGO': order.paymentMethod.name,
            'ESTADO_PAGO': order.paymentMethod.paymentInfo ? order.paymentMethod.paymentInfo.status : "Diferido"
          }
        })
        : []

      dispatch({
        type: EXPORT_ORDERS,
        payload: headersExported
      });
    }

  const exportOrderDetails = async filters => {
    const ordersResponse = await orderService.fetchOrders(filters.query, filters.status, filters.type,0, 1000);

    let detailsExported = [];
    if (ordersResponse.data) {
      for (let order of ordersResponse.data) {
        order.details.forEach(detail => {
          detailsExported.push({
            'REF_PEDIDO': order.erpId,
            'REF_PRODUCTO': detail.price.product.erpId,
            'PRODUCTO': detail.price.product.name,
            'FAMILIA': detail.price.product.families[0].name,
            'CANTIDAD': detail.quantity,
            'PRECIO_UNITARIO': detail.price.value.toFixed(2)
          })
        })
      }
    }

    dispatch({
      type: EXPORT_DETAILS,
      payload: detailsExported
    })
  }

  const sendOrderEmail = async orderId => {
      orderService.sendOrderEmail(orderId).then(_ => {
        dispatch({
          type: API_RESPONSE,
          payload: {
            action: 'get',
            message: 'Email enviado correctamente',
            type: 'success'
          }
        })
      }).catch(_ => {
        dispatch({
          type: API_RESPONSE,
          payload: {
            action: 'get',
            message: 'No se ha podido enviar el email',
            type: 'error'
          }
        })
      })
  }

    return (
      <OrderContext.Provider value={{
        apiResult: state.apiResult,
        detailsExported: state.detailsExported,
        filters: state.filters,
        loading: state.loading,
        orders: state.orders,
        ordersExported: state.ordersExported,
        ordersImported: state.ordersImported,
        orderPicked: state.orderPicked,
        totalOrders: state.totalOrders,
        changeFilters,
        cleanImportExportResponse,
        clearApiStatus,
        exportOrderDetails,
        exportOrderHeaders,
        getOrderById,
        launchMessage,
        importDetails,
        importOrders,
        sendOrderEmail,
        updateOrderDetails,
        updateOrderPaymentInfo,
        updateOrderStatus
      }}>
        {props.children}
      </OrderContext.Provider>
    )
};

export default OrderState;
