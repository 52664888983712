import ClientContext from './ClientContext';
import {
  ADD_CLIENT,
  API_RESULT,
  CHANGE_FILTER,
  CLEAN_API_RESULT,
  CLEAR_IMPORT_EXPORT_CLIENTS_RESPONSE,
  CLIENTS_CHANGE,
  DELETE_CLIENT,
  DELETE_CLIENT_PRICE,
  EXPORT_CLIENTS_RESPONSE,
  IMPORT_CLIENTS_RESPONSE,
  INIT_IMPORTS,
  PICK_CLIENT,
  UPDATE_CLIENT,
  UPSERT_CLIENT_PRICE
} from './ClientActions';
import React, { useReducer, useEffect } from 'react';
import ClientReducer from './ClientReducer';
import addressService from '../../services/addressService';
import clientService from '../../services/clientService';
import orderService from '../../services/orderService';
import priceService from '../../services/priceService';

const ClientState = props => {
  const initialState = {
    apiResult: null,
    clients: [],
    filters: null,
    totalClients: 0,
    commercialPicked: null,
    clientPicked: null,
    clientAddresses: [],
    clientOrders: [],
    clientPrices: [],
    clientsImported: null,
    clientsExported: null,
    loading: false
  };

  const [state, dispatch] = useReducer(ClientReducer, initialState);

  useEffect(() => {
    if (state.filters) fetchClients();
    // eslint-disable-next-line
  }, [state.filters]);

  const fetchClients = async () => {
    const clientsResponse = state.filters.commercialPicked
      ? await clientService.fetchClientsByCommercialAndFilters(state.filters.commercialPicked.id, state.filters.query, state.filters.type, state.filters.page)
      : await clientService.fetchClientsByFilters(state.filters.query, state.filters.type, state.filters.page);

    if (clientsResponse) {
      dispatch({
        type: CLIENTS_CHANGE,
        payload: {
          clients: clientsResponse.data ? clientsResponse.data : [],
          totalClients: clientsResponse.headers['x-total-count'] ? clientsResponse.headers['x-total-count'] : 0
        }
      });
    }
  };

  const changeFilters = async filters => {
    dispatch({
      type: CHANGE_FILTER,
      payload: filters
    });
  };

  const findClientById = async clientId => {
    const client = await clientService.findClientById(clientId);
    const clientOrders = await orderService.findByClientId(clientId);
    const clientPrices = await priceService.findByClientId(clientId);
    const clientAddresses = await addressService.findAddressByClientId(clientId);

    console.log(clientAddresses);

    dispatch({
      type: PICK_CLIENT,
      payload: {
        client,
        clientOrders,
        clientPrices,
        clientAddresses
      }
    });
  };

  const importClients = async data => {
    dispatch({
      type: INIT_IMPORTS
    });

    let success = 0;
    let errors = 0;

    await Promise.all(data.map(client => clientService.importClient(client)
      .then(_ => success += 1)
      .catch(_ => errors += 1)));

    dispatch({
      type: IMPORT_CLIENTS_RESPONSE,
      payload: {
        success,
        errors
      }
    });

    fetchClients();
  };

  const exportClients = async () => {

    const clientResponse = await clientService.fetchClientsByFilters(state.filters.query, state.filters.type, 0, 0);

    const clientsExported =
      clientResponse.data
        ? clientResponse.data.map(client => {
          return {
            'ID': client.erpId,
            'NOMBRE': client.name,
            'EMAIL': client.email,
            'TELEFONO': client.phone,
            'CP': client.postalCode,
            'TIPO': client.type,
            'TARIFA': client.fee.erpId
          };
        })
        : [];

    dispatch({
      type: EXPORT_CLIENTS_RESPONSE,
      payload: clientsExported
    });
  };

  const cleanImportExportClients = () => {
    dispatch({
      type: CLEAR_IMPORT_EXPORT_CLIENTS_RESPONSE
    });
  };

  const deleteClient = clientId => {
    clientService.deleteClient(clientId).then(_ => {
      dispatch({
        type: DELETE_CLIENT,
        payload: clientId
      });
    }).catch(_ => {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'delete',
          status: 'error',
          message: 'No se ha podido eliminar el cliente'
        }
      });
    });
  };

  const deleteClientPrice = priceId => {
    priceService.deletePrice(priceId).then(_ => {
      dispatch({
        type: DELETE_CLIENT_PRICE,
        payload: priceId
      });
    }).catch(_ => {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido eliminar el precio de cliente'
        }
      });
    });
  };

  const updateClientStatus = async (clientId, status) => {
    const updatedClient = await clientService.updateClientStatus(clientId, status);

    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar el estado del cliente'
        }
      });
    }
  };

  const changeClientHidden = async (clientId, hidden) => {
    const updatedClient = await clientService.updateClientHidden(clientId, hidden);

    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar el estado del cliente'
        }
      });
    }
  }

  const updateClientCommercial = async (clientId, commercialId) => {
    const updatedClient = await clientService.updateClientCommercial(clientId, commercialId);
    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar el comercial del cliente'
        }
      });
    }
  };

  const updateClientFee = async (clientId, feeId) => {
    const updatedClient = await clientService.updateClientFee(clientId, feeId);
    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar la tarifa del cliente'
        }
      });
    }
  };

  const updateClientPaymentMethod = async (clientId, paymentMethodId) => {
    const updatedClient = await clientService.updateClientPaymentMethod(clientId, paymentMethodId);

    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar la tarifa del cliente'
        }
      });
    }
  };

  const updateClientShippingMethod = async (clientId, shippingMethodId) => {
    const updatedClient = await clientService.updateClientShippingMethod(clientId, shippingMethodId);
    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar la tarifa del cliente'
        }
      });
    }
  };

  const upsertClientPrice = async (clientId, productId, value) => {
    const updatedPrice = await priceService.upsertPriceByClientId(clientId, productId, value);

    if (updatedPrice) {
      dispatch({
        type: UPSERT_CLIENT_PRICE,
        payload: updatedPrice
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar el precio para el cliente'
        }
      });
    }
  };

  const cleanApiResult = () => {
    dispatch({
      type: CLEAN_API_RESULT
    });
  };

  const sendClientEmail = async (clientId, type) => {
    clientService.sendClientEmail(clientId, type).then(_ => {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'get',
          message: 'Email enviado correctamente',
          status: 'success'
        }
      });
    }).catch(_ => {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'get',
          message: 'No se ha podido enviar el email',
          status: 'error'
        }
      });
    });
  };

  const createClient = async (clientForm) => {
    const newClient = await clientService.createClient(clientForm);
    if (newClient) {
      dispatch({
        type: ADD_CLIENT,
        payload: newClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'add',
          message: 'No se ha podido crear el cliente',
          status: 'error'
        }
      });
    }
  }

  const updateClient = async (clientForm, clientId) => {
    const updatedClient = await clientService.updateClient(clientForm, clientId);
    if (updatedClient) {
      dispatch({
        type: UPDATE_CLIENT,
        payload: updatedClient
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'update',
          status: 'error',
          message: 'No se ha podido actualizar la tarifa del cliente'
        }
      });
    }
  };

  return (
    <ClientContext.Provider value={{
      apiResult: state.apiResult,
      clients: state.clients,
      clientsExported: state.clientsExported,
      clientsImported: state.clientsImported,
      clientAddresses: state.clientAddresses,
      clientOrders: state.clientOrders,
      clientPicked: state.clientPicked,
      clientPrices: state.clientPrices,
      filters: state.filters,
      loading: state.loading,
      totalClients: state.totalClients,

      changeClientHidden,
      changeFilters,
      cleanApiResult,
      cleanImportExportClients,
      createClient,
      deleteClient,
      deleteClientPrice,
      exportClients,
      findClientById,
      importClients,
      sendClientEmail,
      updateClient,
      updateClientCommercial,
      updateClientFee,
      updateClientPaymentMethod,
      updateClientShippingMethod,
      updateClientStatus,
      upsertClientPrice
    }}>
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientState;
