import * as firebase from 'firebase';
import Resizer from "react-image-file-resizer";
import uuid from 'react-uuid';

class FirebaseService {
  constructor() {
    firebase.initializeApp({
      apiKey: "AIzaSyAXQLDpz4Iebn8y-EixdP7GFCn5oqcm0w4",
      authDomain: "distributio-clean-panel.firebaseapp.com",
      databaseURL: "https://distributio-clean-panel.firebaseio.com",
      projectId: "distributio-clean-panel",
      storageBucket: "distributio-clean-panel.appspot.com",
      messagingSenderId: "823064104074",
      appId: "1:823064104074:web:93780eb0aec2abdce33173",
      measurementId: "G-KPDDREKHM4"
    })
  }

  _compressFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1280,
        1280,
        'JPEG',
        90,
        0,
        uri => {
          resolve(uri)
        },
        'blob'
      );
    });

  uploadImage = async file => {
    const compressImage = await this._compressFile(file);

    const storageRef = firebase.storage().ref(`images/${uuid()}.jpg`);
    await storageRef.put(compressImage);
    return await storageRef.getDownloadURL();
  }

  uploadPdf = async file => {
    const storageRef = firebase.storage().ref(`pdfs/${uuid()}.pdf`);
    await storageRef.put(file);
    console.log(storageRef);
    return await storageRef.getDownloadURL();
  }

  authWithMailAndPass = async (email, pass) =>
    firebase.auth().signInWithEmailAndPassword(email, pass)

  getAuthToken = () => firebase.auth().currentUser.getIdTokenResult(true)

  changePassword = async (email, oldPass, newPass) => {
    await this.authWithMailAndPass(email, oldPass);
    return firebase.auth().currentUser.updatePassword(newPass)
  }
}

const firebaseService = new FirebaseService();

export default firebaseService;
